import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import About from './About/About'
import Projects from './Projects/Projects'
import Team from './Team/Team'
import MainPage from './MainPage/MainPage'

const Routes = React.memo(() => {
  return (
    <BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
      <Switch>
        <Route exact path="/">
          <MainPage />
        </Route>
        <Route path="/about/">
          <About />
        </Route>
        <Route path="/projects/">
          <Projects />
        </Route>
        <Route path="/team/">
          <Team />
        </Route>
      </Switch>
    </BrowserRouter>
  )
})

export default Routes
