import React from 'react'
import styles from './Projects.module.scss'
import { NavLink } from 'react-router-dom'

const ourProjects = [
  {
    id: 1,
    name: 'Nuckles',
    description:
      'This chat-bot was based on the Levenshtein Algorithm scoring.\n' +
      '\n' +
      'This chatbot was developed in 40 hours and trained in 5 hours within the framework of the International Engineering Championship “CASE-IN”.',
    links: [
      {
        name: 'Main site of NUCKles ChatBot',
        href: 'http://nuckles.blur.tech/',
      },
      {
        name: 'Github repository of backend on JavaScript with Node.js',
        href: 'https://github.com/blurtech/bot-backend',
      },
      {
        name: 'Github repository of frontend on JavaScript with React',
        href: 'https://github.com/blurtech/bot-frontend',
      },
    ],
  },
  {
    id: 2,
    name: 'Trasher',
    description:
      'Thrasher is an application that motivates users to separate garbage collection. ' +
      '\n' +
      'The idea of the program includes the use of special codes on garbage packages.',
    links: [
      {
        name: 'Github repository of Android on Kotlin',
        href: 'https://github.com/blurtech/trasher-android',
      },
      {
        name: 'Github repository of backend on JavaScript with Mongoose',
        href: 'https://github.com/blurtech/trasher-backend',
      },
      {
        name: 'Github repository of frontend on JavaScript with React',
        href: 'https://github.com/blurtech/trasher-frontend',
      },
    ],
  },
  {
    id: 3,
    name: 'SexSquare',
    description:
      'The Sexsquare allows you to mark places where you had sex, find other users and have sex together.' +
      '\n' +
      'This application is for confident people who are interested in looking at the statistics of their intimate life.',
    links: [
      {
        name: 'Github repository of Android on Kotlin',
        href: 'https://github.com/blurtech/sexsquare-android',
      },
      {
        name: 'Github repository of backend on Java',
        href: 'https://github.com/blurtech/sexsquare-backend-java',
      },
      {
        name: 'Github repository of frontend on JavaScript with React',
        href: 'https://github.com/blurtech/sexsquare-frontend',
      },
    ],
  },
  {
    id: 4,
    name: 'Blurred Education',
    description:
      'Blurred Education is a Microsoft-sponsored IT immersion platform started in 2019. ' +
      '\n' +
      'We share our experience, we simplify about complex and learn programming together.',
    links: [
      {
        name: 'Main Github repository',
        href: 'https://github.com/blurtech/Blurred-Education',
      },
      {
        name: 'Github repository of backend on Java',
        href: 'https://github.com/blurtech/BlurredEducation.Backend',
      },
    ],
  },
  {
    id: 5,
    name: 'Candidate',
    description:
      'The Candidate app was developed during the regional phase of the Digital Breakthrough in the summer of 2019. ' +
      '\n' +
      'It is designed to motivate users to vote for public and government initiatives.',
    links: [
      {
        name: 'Github repository of Android on Kotlin',
        href: 'https://github.com/blurtech/candidate-android',
      },
      {
        name: 'Github repository of frontend on Javascript',
        href: 'https://github.com/blurtech/candidate-frontend',
      },
      {
        name: 'Github repository of backend on Javascript',
        href: 'https://github.com/blurtech/candidate-backend',
      },
    ],
  },
  {
    id: 6,
    name: 'Blurred Board',
    description:
      'The Blurred Board app is a scorboard designed for noCTF information security competitions. ' +
      '\n' +
      'It is intended to replace obsolete scorboards, displaying all the results in real time.',
    links: [
      {
        name: 'Github repository of project',
        href: 'https://github.com/blurtech/blurred-board',
      },
    ],
  },
  {
    id: 7,
    name: 'Everwriter',
    description:
      'The Everwriter was coined in the spring of 2019 as part of Tensor.' +
      '\n' +
      'It is an offline document editor with synchronization between devices. It works on web-sockets.',
    links: [
      {
        name: 'Github repository of backend on Express',
        href: 'https://github.com/blurtech/everwriter-backend',
      },
      {
        name: 'Github repository of frontend on Typescript',
        href: 'https://github.com/blurtech/everwriter-frontend',
      },
    ],
  },
  {
    id: 8,
    name: 'Neverseen',
    description:
      'Neversen is an application for people with visual impairments. ' +
      '\n' +
      'It is intended to simplify their navigation.',
    links: [],
  },
]

const Projects = () => {
  const [currentProject, setCurrentProject] = React.useState(0)
  return (
    <div className={styles.root}>
      <div className={styles.head}>
        <div className={styles.header}>/projects</div>
        <nav className={styles.navigation}>
          <span className={styles.navMenu}>
            <NavLink to="/" className={styles.navLink}>
              /main
            </NavLink>
          </span>
          <span className={styles.navMenu}>
            <NavLink to="/team/" className={styles.navLink}>
              /team
            </NavLink>
          </span>
          <span className={styles.navMenu}>
            <NavLink to="/about/" className={styles.navLink}>
              /about
            </NavLink>
          </span>
        </nav>
      </div>
      <div className={styles.page}>
        <div className={styles.left}>
          <div className={styles.mainMenu}>
            {ourProjects.map((project) => (
              <span
                className={styles.menu}
                onClick={() => {
                  setCurrentProject(project.id - 1)
                }}
              >
                {ourProjects[project.id - 1].name}
              </span>
            ))}
          </div>
          <div className={styles.white}></div>
          <div className={styles.red}></div>
          <div className={styles.black}></div>
        </div>
        <div className={styles.right}>
          <div>
            <div className={styles.stripe}></div>
            <div className={styles.name}>
              {ourProjects[currentProject].name}
            </div>
          </div>
          <div className={styles.description}>
            {ourProjects[currentProject].description}
          </div>
          <div className={styles.links}>
            {ourProjects[currentProject].links.map((link) => (
              <div
                className={styles.link}
                onClick={() => {
                  window.location.href = link.href
                }}
              >
                {link.name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Projects
