import React from 'react'
import styles from './About.module.scss'
import { NavLink } from 'react-router-dom'

const About = () => {
  return (
    <div className={styles.root}>
      <div className={styles.head}>
        <div className={styles.header}>/about</div>
        <nav className={styles.navigation}>
          <span className={styles.navMenu}>
            <NavLink to="/" className={styles.navLink}>
              /main
            </NavLink>
          </span>
          <span className={styles.navMenu}>
            <NavLink to="/team/" className={styles.navLink}>
              /team
            </NavLink>
          </span>
          <span className={styles.navMenu}>
            <NavLink to="/projects/" className={styles.navLink}>
              /projects
            </NavLink>
          </span>
        </nav>
      </div>
      <div className={styles.page}>
        <div className={styles.about}>
          We are a crew of students that develops various software solutions to
          gain experience and make the world better. The team formed the
          intersection of many different interests. We have those who are
          interested in server development, who are interested in the
          development of mobile applications for android, who are interested in
          information security, etc. Our main connecting factor is the general
          idea. We all want to get the experience to become steep developers.
          And we all want to do something cool. Blurred Technologies - blurs
          your life.
        </div>
        <div className={styles.links}>
          <span
            className={styles.link}
            onClick={() => (window.location.href = 'https://vk.com/blurtech')}
          >
            VK
          </span>
          <span
            className={styles.link}
            onClick={() =>
              (window.location.href =
                'https://www.facebook.com/blurredtechnologies')
            }
          >
            Facebook
          </span>
          <span
            className={styles.link}
            onClick={() =>
              (window.location.href = 'https://twitter.com/blur_tech')
            }
          >
            Twitter
          </span>
          <span
            className={styles.link}
            onClick={() => (window.location.href = 'https://t.me/blurtech')}
          >
            Telegram
          </span>
        </div>
      </div>
    </div>
  )
}

export default About
