import React from 'react'
import styles from './Team.module.scss'
import { NavLink } from 'react-router-dom'

const people = [
  {
    id: 1,
    name: 'Alexandr Sidorenko',
    photo: '/assets/images/team/Sidor.png',
    description:
      'Came up with idea that technology needs to be blurred. Tightened a few more people. Participated in various hackathons and CTF. We will blur you next!',
    links: {
      twitter: 'https://twitter.com/batyshkaLenin',
      instagram: 'https://www.instagram.com/alexander_sidorenko/',
    },
    specializing: 'Team Leader, Frontend Developer',
    workExperience: 'Since 2019 year I have been working in CleverCraft',
    interest: 'Information Security, rock music and compiler development',
  },
  {
    id: 2,
    name: 'Sergey Grechishnikov',
    photo: '/assets/images/team/Grech.png',
    description:
      'Co-founder of Blurred Technologies and many related projects. Lead mobile developer and UI critic. In spare time looks at backends and CI/CD.',
    links: {
      twitter: 'https://twitter.com/srggrch',
      instagram: 'https://www.instagram.com/srggrch/',
    },
    specializing: 'Team Leader, Mobile Developer',
    workExperience: 'Three years of Android developing',
    interest: 'Design, video and photo shooting and music',
  },
  {
    id: 3,
    name: 'Alexander Bulatov',
    photo: '/assets/images/team/Bulat.png',
    description:
      'Software engineer Bachelor and Magister student at NSTU ACEF with ambitious for a PhD in Computer Science. Love to work with data: analyzing, processing, storing. Also creating backend in hackatons. Currently work as Data Engineer at Beeline Big Data team.',
    links: {
      twitter: 'https://twitter.com/drunkdba',
      instagram: 'https://www.instagram.com/mad_dog_of_siberia/',
    },
    specializing: 'Data Engineer and Backend developer',
    workExperience: '1.5 years of work for money',
    interest: 'Lagers, Porters, Stouts',
  },
  {
    id: 4,
    name: 'Matt Yanov',
    photo: '/assets/images/team/Yanov.png',
    description:
      'Trying to code as not retarded as possible. Not quite successful. Migrated from embedded to React couple of years ago, still being confused. As long as everyone is ok with my code - i`m simply happy!',
    links: {
      twitter: null,
      instagram: null,
    },
    specializing: 'Frontend Developer',
    workExperience:
      '2 years of industrial development for embedded needs. From 2019 I`m working as React-developer  in CleverCraft',
    interest: 'Cars, You, Food',
  },
  {
    id: 5,
    name: 'Maria Kalinina',
    photo: '/assets/images/team/Kalinina.png',
    description:
      'Hello, something interesting supposed to be here, i guess, but...\n' +
      'When I`m not studying in NSTU I do programming (work and side projects).\n' +
      'I dislike to write text, I like to write code.',
    links: {
      twitter: null,
      instagram: null,
    },
    specializing: 'Frontend React Developer',
    workExperience: 'From 2019 I`m working in CleverCraft',
    interest: 'Looking for bullshit UX/UI and hate its developers',
  },
  {
    id: 6,
    name: 'Nina Torgunakova',
    photo: '/assets/images/team/Torgun.png',
    description:
      'Nina is a media adjutant of Blurred Education and a beginning frontend developer. In her free time she shoots not only with her eyes and writes sales texts such as this one.',
    links: {
      twitter: 'https://twitter.com/GnaSeagull',
      instagram: 'https://www.instagram.com/nina_seagull/',
    },
    specializing: 'Frontend Developer',
    workExperience: 'Dark past as a few years works as a copywriter',
    interest: 'Writing, shooting, monkeys and programming',
  },
  {
    id: 7,
    name: 'Vitaly Shatalov',
    photo: '/assets/images/team/Kot.png',
    description:
      'He is been developing since 2012, he is been idling a lot, he is been writing in PHP for 6 years. He is engaged in music. Since 2019, he has been writing on Node.js.\n' +
      'Wants to leave the frontend, but no possibility.',
    links: {
      twitter: null,
      instagram: null,
    },
    specializing: 'Full-stack Web development, neural networks.',
    workExperience:
      '8 years of web development, 6 months of Reinforcement learning.',
    interest: 'Kicking a cunt',
  },
  {
    id: 8,
    name: 'Danil Tankov',
    photo: '/assets/images/team/Tankov.png',
    description:
      'Young Danya, the youngest in the team. Loves the imperative style, became a front-end developer, although constantly joked that JS is not a language.',
    links: {
      twitter: 'https://twitter.com/danushaperdusha',
      instagram: 'https://www.instagram.com/danushaperdusha/',
    },
    specializing: 'Frontend Developer',
    workExperience: '',
    interest: 'Standups of Romanov and Filatov',
  },
]

const Team = () => {
  const [currentPerson, setCurrentPerson] = React.useState(0)

  React.useEffect(() => {
    people.forEach((i) => (new Image().src = i.photo))
  }, [])

  return (
    <div className={styles.root}>
      <div className={styles.head}>
        <div className={styles.header}>/team</div>
        <nav className={styles.navigation}>
          <span className={styles.navMenu}>
            <NavLink to="/" className={styles.navLink}>
              /main
            </NavLink>
          </span>
          <span className={styles.navMenu}>
            <NavLink to="/projects/" className={styles.navLink}>
              /projects
            </NavLink>
          </span>
          <span className={styles.navMenu}>
            <NavLink to="/about/" className={styles.navLink}>
              /about
            </NavLink>
          </span>
        </nav>
      </div>
      <div className={styles.page}>
        <div className={styles.left}>
          <div className={styles.mainMenu}>
            {people.map((person) => (
              <span
                className={styles.menu}
                onClick={() => setCurrentPerson(person.id - 1)}
              >
                {person.name}
              </span>
            ))}
          </div>
        </div>
        <div className={styles.right}>
          <div>
            <div className={styles.stripe}></div>
            <div className={styles.name}>{people[currentPerson].name}</div>
          </div>
          <div className={styles.content}>
            <img
              alt=""
              className={styles.photo}
              src={people[currentPerson].photo}
            />
            <div className={styles.description}>
              {people[currentPerson].description}
              <br />
              <div className={styles.networks}>
                {people[currentPerson].links.twitter && (
                  <span
                    onClick={() => {
                      window.location.href = `${people[currentPerson].links.twitter}`
                    }}
                  >
                    <img
                      alt=""
                      src="/assets/images/team/twitter.png"
                      className={styles.network}
                    />
                  </span>
                )}
                {people[currentPerson].links.instagram && (
                  <span
                    onClick={() => {
                      window.location.href = `${people[currentPerson].links.instagram}`
                    }}
                  >
                    <img
                      alt=""
                      src="/assets/images/team/instagram.png"
                      className={styles.network}
                    />
                  </span>
                )}
              </div>
              <b>Specializing: </b>
              {people[currentPerson].specializing}
              <br />
              <b>Work experience: </b>
              {people[currentPerson].workExperience}
              <br />
              <b>Interests: </b>
              {people[currentPerson].interest}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Team
