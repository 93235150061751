import React from 'react'
import styles from './MainPage.module.scss'
import { NavLink } from 'react-router-dom'

const MainPage = () => {
  const [hover, setHover] = React.useState(false)
  return (
    <div className={styles.index}>
      <div className={styles.header}>
        <span
          onMouseOver={() => {
            setHover(true)
          }}
          onMouseLeave={() => {
            setHover(false)
          }}
          className={hover ? styles.blurredActive : styles.blurred}
        >
          blurred
        </span>
        <br />
        <span className={styles.technologies}> technologies/.</span>
      </div>
      <nav className={styles.navigation}>
        <span className={styles.menu}>
          <NavLink to="/projects/" className={styles.link}>
            /projects
          </NavLink>
        </span>
        <span className={styles.menu}>
          <NavLink to="/team/" className={styles.link}>
            /team
          </NavLink>
        </span>
        <span className={styles.menu}>
          <NavLink to="/about/" className={styles.link}>
            /about
          </NavLink>
        </span>
      </nav>
    </div>
  )
}

export default MainPage
